import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';

export default class HomePageSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.home-page-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const HomePageSwiper = new Swiper('.home-page-swiper', {

                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                  },

//                   autoplay: {
//                     delay: 5000,
//                     disableOnInteraction: false,
//                   },

                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,

                modules: [ Pagination, Autoplay ],

            })
        })
    }
}
