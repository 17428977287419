import '@styles/tailwind.css';
import '@styles/main.scss';

import HomePageSwiper from './components/home-page-swiper'
import menu from './components/menu';


class App {
    constructor() {
        new HomePageSwiper();
        menu();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
